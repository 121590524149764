.rows {
  position: relative;
  width: 100%;
  margin: 0;
  margin-top: 50px;
  padding: 0;
  list-style: none;
}

.row__img {
  display: none;
  position: absolute;
  max-width: 500px;
  max-height: 500px;
  top: random(250) - random(250) + px;
  left: random(500) + px;
  z-index: -1;
  opacity: 0;
  transition: opacity 500ms;
}

.row__cols,
.row__post {
  display: block;
  margin-bottom: 10px;
}

.row__cols {
  font-weight: 700;
  overflow: hidden;
}

.row__post {
  width: 100%;
  overflow: hidden;
}

.row__title,
.row__date,
.row__cat {
  float: left;
}

.row__title {
  width: 100%;
}

.row__date,
.row__cat {
  width: auto;
}

.row__cat {
  position: relative;
  margin-left: 10px;

  &:before {
    position: absolute;
    content: '|';
    left: -9px;
  }
}

.row__body {
  margin: 20px 0;
}

// behaviors
.row__active {
  text-decoration: line-through;
}

// media queries
@include media-query($on-laptop) {
  .row__post {
      &:hover {
      * {
        text-decoration: underline;
        cursor: pointer;
      }

      .row__active {
        text-decoration: line-through;
      }

      .row__img {
        display: block;
        opacity: 0.5;
      }
    }

    &:nth-child(2n) .row__img {
      top: random(250) - random(250) + px;
      left: random(500) + px;
    }

    &:nth-child(3n) .row__img {
      top: random(250) - random(250) + px;
      left: random(500) + px;
    }

    &:nth-child(4n) .row__img {
      top: random(250) - random(250) + px;
      left: random(500) + px;
    }

    &:nth-child(5n) .row__img {
      top: random(250) - random(250) + px;
      left: random(500) + px;
    }
  }
}

@include media-query($on-palm) {
  .row__cols,
  .row__post {
    margin: 0;
  }

  .row__title {
    width: 50%;
  }

  .row__date,
  .row__cat {
    width: 25%;
  }

  .row__cat {
    margin: 0;

    &:before {
      content: '';
    }
  }
}
