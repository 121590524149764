.bio {
  max-width: 800;
  margin-bottom: 50px;
}

.bio__header {
  margin-bottom: 10px;
  font-weight: 700;

  a {
  	text-decoration: none;

  	&:hover {
  		text-decoration: none;
  	}
  }
}

.bio__social {
    margin: 0px;
    margin-top: 10px;
    padding: 0;
    list-style: none;
    li {
        margin-right: 10px;
        float: left;
    }
}

