$on-palm:           600px !default;
$on-laptop:         800px !default;

// typography
@import url('https://fonts.googleapis.com/css?family=Inconsolata:400,700');
$inconsolata:       'Inconsolata', monospace;
$laptop_font__size:        1.5rem;
$palm_font__size:        1rem;
$font__weight:      400;
$font__line-height: 1.5;

// colors
$color__black:      #000000;
$color__gray:       #eeeeee;
$color__beige:      #f8f8f1;
$color__yellow:     #fefeca;

@mixin media-query($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

// elements
* {
  box-sizing: border-box;
}

::selection {
  background: $color__yellow;
}

::-moz-selection {
  background: $color__yellow;
}

hr {
  color: $color__black;
  background-color: $color__black;
}

a {
  // display: block;
  // width: 100%;
  // height: 100%;
  color: $color__black;
  text-decoration: underline;

  &:hover {
    color: $color__black;
  }
}

// layout
html {
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  width: 100%;
  margin: 25px 0;
  padding: 0;
  background: $color__beige;
  font-family: $inconsolata;
  font-weight: $font__weight;
  line-height: $font__line-height;
}

.container {
  max-width: 1200px;
  margin: 0 25px;
}

// media queries
@include media-query($on-palm) {
  body {
    margin: 50px 0 25px;
    font-size: $palm_font__size;
  }

  .container {
    margin: 0 50px;
  }
}

@include media-query($on-laptop) {
  body {
    margin: 60px 0 50px;
    font-size: $laptop_font__size;
  }

  .container {
    margin: 0 120px;
  }
}

th, td {
  padding: 0px 10px 0px 10px;
  border: 2px solid;
  border-radius: 10px;
}
