.post__meta {
  margin: 30px 0 20px;
  border-bottom: 1px solid $color__black;
  overflow: hidden;
  font-weight: 700;
}

.post__paginate {
  overflow: hidden;
}

$paginate-padding: 13px;
.post__paginate-left,
.post__paginate-right {
  position: relative;
  display: block;
  width: 50%;
}

.post__paginate-left {
  padding-left: $paginate-padding;
  float: left;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "\21FD";
  }
}

.post__paginate-right {
  padding-right: $paginate-padding;
  text-align: right;
  float: right;

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    content: "\21FE";
  }
}

.post__title {
  @extend .row__title;
}

.post__date {
  @extend .row__date;
}

.post__cat {
  @extend .row__cat;
}

.post__body {
  padding-bottom: 20px;
  // border-bottom: 1px solid $color__black;

  pre {
    background: $color__gray;
    padding: 10px 20px;
  }

  code {
    background: $color__gray;
    font-size: 12px;
  }

  img {
    max-width: 100%;
  }
}

// media queries
@include media-query($on-laptop) {
  .post {
    min-width: 500px;
    width: 50%;
  }
}
